/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Container, Link, styled, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import Layout from 'components/partials/Layout';
import { Arrow } from 'components/UI/Link';
import { Breakpoints } from 'utils/enums';

const ForOForSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(25),
  marginTop: theme.spacing(25),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'flex',
    marginTop: theme.spacing(40),
  },
}));

const ForOForContainer = styled(Container)({
  position: 'relative',
});

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 auto',
  maxWidth: theme.typography.pxToRem(320),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: theme.typography.pxToRem(531),
  },
}));

const BelowContent = styled('div')(({ theme }) => ({
  margin: `${theme.typography.pxToRem(48)} auto 0`,
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: theme.typography.pxToRem(531),
  },
}));

const ForOForTitle = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(140),
  fontWeight: 600,
  lineHeight: theme.typography.pxToRem(140),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: theme.typography.pxToRem(240),
    fontWeight: 600,
    lineHeight: theme.typography.pxToRem(200),
  },
}));

const ForOForText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {},
}));

const ForOForArrow = styled(Arrow)(({ theme }) => ({
  marginRight: theme.spacing(3),
  transform: 'rotate(180deg)',

  [theme.breakpoints.up(Breakpoints.Md)]: {},
}));

const ForOForCTA = styled(Link)(({ theme }) => ({
  '&:hover': {
    opacity: '0.6',
    textDecoration: 'none',
  },
  alignItems: 'center',
  display: 'inline-flex',
  fontWeight: 500,
  paddingTop: theme.spacing(6),
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
  }),

  [theme.breakpoints.up(Breakpoints.Md)]: {},
}));

const browser = typeof window !== 'undefined' && window;

const ForOFor: React.FC<ForOForProps> = ({ data }) => {
  if (!browser) {
    return null;
  }
  return (
    <Layout
      title={data.foroforYaml.metadata.title}
      description={data.foroforYaml.metadata.description}
    >
      <ForOForSection>
        <ForOForContainer>
          <Content>
            <ForOForTitle>404</ForOForTitle>
          </Content>
          <BelowContent>
            <ForOForText>{data.foroforYaml.text.firstLine}</ForOForText>
            <ForOForText>{data.foroforYaml.text.secondLine}</ForOForText>
            <ForOForCTA href={data.foroforYaml.cta.url}>
              <ForOForArrow />
              {data.foroforYaml.cta.text}
            </ForOForCTA>
          </BelowContent>
        </ForOForContainer>
      </ForOForSection>
    </Layout>
  );
};

interface ForOForProps {
  data: {
    foroforYaml: {
      metadata: {
        title: string;
        description: string;
      };
      text: {
        firstLine: string;
        secondLine: string;
      };
      cta: {
        text: string;
        url: string;
      };
    };
  };
}

export const query = graphql`
  query ForOForQuery {
    foroforYaml {
      metadata {
        description
        title
      }
      text {
        firstLine
        secondLine
      }
      cta {
        text
        url
      }
    }
  }
`;

export default ForOFor;
